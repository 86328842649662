var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        attrs: { id: "abl-title", "page-title": _vm.assignment.title },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({ name: "assignments" })
                      },
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v("arrow_back"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "top d-flex justify-end" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-0" },
                      [
                        !(_vm.screenSize < 900)
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("span", { staticClass: "time-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language
                                        ? "Upload Date"
                                        : "প্রকাশের তারিখ"
                                    ) + ": "
                                  ),
                                ]),
                                _c("span", { staticClass: "time-value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.customDateFormat(
                                        _vm.assignment.upload_date,
                                        _vm.$language
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        !(_vm.screenSize < 900)
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon-app",
                                  attrs: {
                                    src: require("#ecf/assignment/assets/Share With Batches.svg"),
                                    alt: "",
                                  },
                                }),
                                !_vm.$language
                                  ? _c("span", { staticClass: "batch-info" }, [
                                      _vm._v(
                                        "With " +
                                          _vm._s(_vm.batchesData.length) +
                                          " Batches"
                                      ),
                                    ])
                                  : _c("span", { staticClass: "batch-info" }, [
                                      _vm._v(
                                        _vm._s(_vm.batchesData.length) +
                                          " টি ব্যাচের সাথে শেয়ার করা হয়েছে"
                                      ),
                                    ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end",
                            attrs: { cols: "5" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "publish-all-button white--text",
                                attrs: {
                                  color: "#0099dc",
                                  loading: _vm.grading["all"],
                                },
                                on: { click: _vm.massPublish },
                              },
                              [
                                _c("img", {
                                  staticClass: "publish-all-icon",
                                  attrs: {
                                    src: require("#ecf/assignment/assets/Publish All Batch.svg"),
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !(_vm.screenSize < 900),
                                        expression: "!(screenSize < 900)",
                                      },
                                    ],
                                    staticClass: "publish-all-text",
                                    class: {},
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language
                                          ? "Publish All Batch"
                                          : "সকল ব্যাচ প্রকাশ করুন"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "my-5" }),
      _c("v-container", { staticClass: "py-0 my-0" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.screenSize < 900,
                expression: "screenSize < 900",
              },
            ],
          },
          [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _vm.screenSize < 900
                  ? _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c("span", { staticClass: "time-text" }, [
                        _vm._v(
                          _vm._s(
                            !_vm.$language ? "Upload Date" : "প্রকাশের তারিখ"
                          ) + ": "
                        ),
                      ]),
                      _c("span", { staticClass: "time-value" }, [
                        _vm._v(
                          _vm._s(
                            _vm.customDateFormat(
                              _vm.assignment.upload_date,
                              _vm.$language
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.screenSize < 900
                  ? _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c("img", {
                        staticClass: "icon-app",
                        attrs: {
                          src: require("#ecf/assignment/assets/Share With Batches.svg"),
                          alt: "",
                        },
                      }),
                      !_vm.$language
                        ? _c("span", { staticClass: "batch-info" }, [
                            _vm._v(
                              "With " +
                                _vm._s(_vm.batchesData.length) +
                                " Batches"
                            ),
                          ])
                        : _c("span", { staticClass: "batch-info" }, [
                            _vm._v(
                              _vm._s(_vm.batchesData.length) +
                                " টি ব্যাচের সাথে শেয়ার করা হয়েছে"
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        [
          _vm.loadingCondition
            ? [
                _c("div", { attrs: { id: "loading-wrapper" } }, [
                  _c("div", { attrs: { id: "loading-text" } }, [
                    _vm._v("LOADING"),
                  ]),
                  _c("div", { attrs: { id: "loading-content" } }),
                ]),
              ]
            : _vm.noAssignmentSubmissionShowCondition
            ? _c("no-content-component", {
                attrs: { message: _vm.noAssignmentSubmissionMessage },
              })
            : _vm._l(_vm.sharedObjects, function (share, index) {
                return _c("mk-assignment-expansion-panel", {
                  key: index,
                  attrs: { "shared-object": share },
                  on: { updateBatch: _vm.updateBatchStudent },
                })
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }