<template>
  <v-container>
    <page-title id="abl-title" :page-title="assignment.title">
      <!-- <template #title>
        <div
          class="title-text xl:text-2xl lg:text-2xl md:text-xl sm:text-xl ft-bold"
        >
          {{ assignment.title }}
        </div>
      </template> -->
      <template v-slot:left>
        <v-btn icon @click="$router.replace({ name: 'assignments' })">
          <v-icon class="mr-2">arrow_back</v-icon>
        </v-btn>
      </template>

      <template #action>
        <div class="top d-flex justify-end">
          <v-row class="ma-0">
            <v-col
              cols="4"
              v-if="!(screenSize < 900)"
              class="d-flex align-center"
            >
              <span class="time-text"
                >{{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }}:
              </span>
              <span class="time-value">{{
                customDateFormat(assignment.upload_date, $language)
              }}</span>
            </v-col>
            <v-col
              cols="3"
              v-if="!(screenSize < 900)"
              class="d-flex align-center"
            >
              <img
                class="icon-app"
                :src="require('#ecf/assignment/assets/Share With Batches.svg')"
                alt=""
              />
              <span v-if="!$language" class="batch-info"
                >With {{ batchesData.length }} Batches</span
              >
              <span v-else class="batch-info"
                >{{ batchesData.length }} টি ব্যাচের সাথে শেয়ার করা হয়েছে</span
              >
            </v-col>

            <v-col cols="5" class="d-flex justify-end">
              <v-btn
                color="#0099dc"
                class="publish-all-button white--text"
                :loading="grading['all']"
                @click="massPublish"
              >
                <img
                  class="publish-all-icon"
                  :src="require('#ecf/assignment/assets/Publish All Batch.svg')"
                  alt=""
                />
                <span
                  v-show="!(screenSize < 900)"
                  class="publish-all-text"
                  :class="{}"
                  >{{
                    !$language ? "Publish All Batch" : "সকল ব্যাচ প্রকাশ করুন"
                  }}</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </page-title>
    <div class="my-5"></div>
    <v-container class="py-0 my-0">
      <div v-show="screenSize < 900">
        <v-row no-gutters>
          <v-col v-if="screenSize < 900" class="d-flex align-center">
            <span class="time-text"
              >{{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }}:
            </span>
            <span class="time-value">{{
              customDateFormat(assignment.upload_date, $language)
            }}</span>
          </v-col>
          <v-col v-if="screenSize < 900" class="d-flex align-center">
            <img
              class="icon-app"
              :src="require('#ecf/assignment/assets/Share With Batches.svg')"
              alt=""
            />
            <span v-if="!$language" class="batch-info"
              >With {{ batchesData.length }} Batches</span
            >
            <span v-else class="batch-info"
              >{{ batchesData.length }} টি ব্যাচের সাথে শেয়ার করা হয়েছে</span
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-container>
      <template v-if="loadingCondition">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="noAssignmentSubmissionShowCondition"
        :message="noAssignmentSubmissionMessage"
      ></no-content-component>
      <mk-assignment-expansion-panel
        v-else
        v-for="(share, index) in sharedObjects"
        :key="index"
        :shared-object="share"
        @updateBatch="updateBatchStudent"
      ></mk-assignment-expansion-panel>
    </v-container>
  </v-container>
</template>

<script>
import courseworkService from "#ecf/assignment/services/CourseworkService";
import _ from "lodash";
import MkAssignmentExpansionPanel from "#ecf/assignment/components/MkAssignmentExpansionPanel.vue";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "AssignmentDetailsPage",
  components: { MkAssignmentExpansionPanel, NoContentComponent },
  props: {
    courseId: String,
    assignment_id: String,
  },
  provide() {
    return {
      $courseId: () => this.courseId,
    };
  },
  data() {
    return {
      assignment: { title: "" },
      submissions: [],
      batchesData: [],
      grades: {},
      grading: {},
      sharedObjects: [],
      batchStudentGrade: {},
      loadingCourse: true,
      loadingSubmission: true,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    batches() {
      const bs = this.batchesData.map((b) => {
        if (!this.submissions[b.batch_id]) {
          return false;
        } else {
          const clone = { ...b };
          clone.submissions = this.submissions[b.batch_id];
          return clone;
        }
      });
      return _.compact(bs);
    },
    loadingCondition() {
      return this.loadingCourse || this.loadingSubmission;
    },
    noAssignmentSubmissionShowCondition() {
      return this.sharedObjects === undefined || this.sharedObjects.length == 0;
    },
    noAssignmentSubmissionMessage() {
      return this.$language
        ? "বর্তমানে এই এসাইনমেন্ট কোনো ব্যাচ এর সাথে শেয়ার করা নেই"
        : "Currently the assignment is not being shared with any batches";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    await this.fetchCoursework();
    await this.fetchSubmissions();
    this.prepareShared();
  },
  methods: {
    async download() {
      try {
        window.open(this.assignment.url);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },

    async fetchCoursework() {
      try {
        this.loadingCourse = true;
        this.assignment = await courseworkService.get(
          this.assignment_id,
          this.courseId
        );
        this.batchesData = await courseworkService.getSharedBatches(
          this.assignment.object_id,
          this.courseId
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingCourse = false;
      }
    },
    async fetchSubmissions() {
      try {
        this.loadingSubmission = true;
        const submissions = await courseworkService.getSubmissions(
          this.assignment_id,
          this.courseId
        );
        this.submissions = Object.values(submissions);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingSubmission = false;
      }
    },
    async submit_grade(payload) {
      if (this.grading[payload.submission.student_id]) return;
      try {
        this.$set(this.grading, payload.submission.student_id, true);
        await courseworkService.grade(
          this.assignment_id,
          payload.submission.course_id,
          payload.submission.batch_id,
          payload.submission.student_id,
          payload.score
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.grading, payload.submission.student_id, false);
      }
    },
    async massPublish() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      if (this.grading?.all) return;
      try {
        this.$set(this.grading, "all", true);
        for (let item of Object.keys(this.batchStudentGrade)) {
          for (let student of Object.keys(this.batchStudentGrade[item])) {
            if (
              this.batchStudentGrade[item][student] === undefined ||
              this.batchStudentGrade[item][student] < 0
            ) {
              this.$root.$emit("alert", [
                undefined,
                !this.$language
                  ? `All students from every batch needs to be evaluated`
                  : `প্রতিটি ব্যাচের সকল শিক্ষার্থীকে মূল্যায়ন করতে হবে`,
              ]);
              return;
            }
            await courseworkService.grade(
              this.assignment_id,
              this.courseId,
              item,
              student,
              this.batchStudentGrade[item][student]
            );
          }
        }
        await new Promise((res) => setTimeout(res, 750));
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.grading, "all", false);
      }
    },
    prepareShared() {
      try {
        let mapped = {};
        for (let item of this.batchesData) {
          mapped[item.batch_id] = {
            due_date: item.due_date,
            share_date: item.share_date,
            title: item.batch_title,
            id: item.batch_id,
            total: item.total_score,
            submissions: new Array(),
          };
        }
        for (let item of this.submissions) {
          if (mapped[item.batch_id]) {
            mapped[item.batch_id].submissions.push({
              name: item.user_name,
              id: item.student_id,
              appeared: item.upload_date,
              grade: item.grade,
              assignment_id: this.assignment_id,
            });
          }
        }
        this.sharedObjects = Object.values(mapped);
        for (let item of this.sharedObjects) {
          this.$set(this.batchStudentGrade, item.id, {});
          for (let sub of item.submissions) {
            this.$set(this.batchStudentGrade[item.id], sub.id, sub.grade);
          }
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    customDateFormat(date, lang) {
      let langF = "";
      if (!lang) {
        langF = "en-gb";
      } else {
        langF = "bn-BD";
      }
      let customDate = new Date(date);
      return customDate.toLocaleString(langF, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    updateBatchStudent(payload) {
      this.$set(
        this.batchStudentGrade[payload.batch_id],
        payload.student_id,
        payload.grade
      );
    },
  },
};
</script>

<style scoped lang="scss">
#abl-title {
  max-width: 100% !important;
}

.title-text {
  @media only screen and (min-width: 1600px) {
    width: 515px;
  }

  @media only screen and (max-width: 1599px) {
    width: 650px;
  }
}

.top {
  margin-left: auto;
  @media only screen and (min-width: 1600px) {
    width: 850px;
  }

  @media only screen and (max-width: 1599px) {
    width: 650px;
  }
}

.icon-app {
  @media only screen and (min-width: 1600px) {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    height: 25px;
    width: 25px;
    margin-right: 6px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
    height: 25px;
    width: 25px;
    margin-right: 6px;
  }

  @media only screen and (max-width: 599px) {
    height: 25px;
    width: 25px;
    margin-right: 6px;
  }
}

.time-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #0000008c;

  @media only screen and (min-width: 1600px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.time-value {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #0099dc;

  @media only screen and (min-width: 1600px) {
    font-size: 16px;
    margin-left: 10px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 12px;
    margin-left: 10px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 599px) {
    font-size: 10px;
    margin-left: 6px;
  }
}

.batch-info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #202020;

  @media only screen and (min-width: 1600px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 12px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.publish-all-button {
  @media only screen and (min-width: 1600px) {
    height: 55px !important;
    width: 270px !important;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    height: 45px !important;
    width: 210px !important;
  }

  @media only screen and (max-width: 899px) {
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
  }
}

.publish-all-icon {
  @media only screen and (min-width: 1600px) {
    height: 40px;
    width: 40px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    height: 30px;
    width: 30px;
  }

  @media only screen and (max-width: 899px) {
    height: 28px;
    width: 28px;
  }
}

.publish-all-text {
  color: white;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  @media only screen and (min-width: 1600px) {
    font-size: 20px;
    margin-left: 10px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 16px;
    margin-left: 6px;
  }

  @media only screen and (min-width: 600px) and (max-width: 899px) {
  }

  @media only screen and (max-width: 599px) {
  }
}
</style>
