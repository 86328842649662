<template>
  <div>
    <div :class="['abl-header', { 'header-open': active }]">
      <v-row no-gutters align="center">
        <v-col :cols="cols_info" class="pa-0">
          <!--this for info-->
          <v-row no-gutters>
            <v-col :cols="cols_info_item" class="pa-0">
              <div class="title-text text-truncate">{{ sharedObject.title }}</div>
            </v-col>
            <v-col
              :class="[
                'pa-0',
                { 'pt-2 pb-1': screenSize < 900 && screenSize > 600 },
              ]"
            >
              <span class="eval-text"
                >{{ !$language ? "Evaluation" : "মূল্যায়ন" }}:</span
              >
              <span class="eval-value"
                >{{ computeEvaluatedStudents }}/{{
                  sharedObject.submissions.length
                }}
                {{ !$language ? "Students" : "জন শিক্ষার্থী" }}</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="cols_action" :order="order_action" class="pa-0">
          <!-- this is for actions-->
          <v-btn
            :disabled="!(sharedObject.submissions.length > 0)"
            depressed
            class="btn-publish white--text px-0"
            :loading="publishig[sharedObject.id]"
            @click="publish()"
          >
            <img
              class="btn-icon"
              :src="require('#ecf/assignment/assets/Publish.svg')"
            />
            <span class="btn-text">{{
              !$language ? "Publish" : "প্রকাশ করুন"
            }}</span>
          </v-btn>
          <v-btn icon class="expand-arrow" @click="expandContent()">
            <v-icon :class="classes.arrow[active]" :size="icon_size">mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
        <v-col
          :cols="cols_time"
          :order="order_time"
          :class="['pa-0', { 'mt-3': screenSize < 600 }]"
        >
          <!-- this is for time-->
          <v-row no-gutters>
            <v-col :cols="order_time_item">
              <span class="eval-text time-desc-text middle-text"
                >{{ !$language ? "Available Date" : "তারিখ" }}:</span
              >
              <span class="eval-value">{{
                customDateFormat(sharedObject.share_date, $language)
              }}</span>
            </v-col>
            <v-col :cols="order_time_item">
              <span class="eval-text time-desc-text"
                >{{ !$language ? "Available Upto" : "তারিখ পর্যন্ত" }}:</span
              >
              <span class="eval-value">{{
                customDateFormat(sharedObject.due_date, $language)
              }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div :class="['abl-content', { 'content-open': active }]">
      <div
        v-if="sharedObject.submissions.length > 0"
        :class="['content-holder', { 'holder-open': active }]"
      >
        <mk-assignment-student-header
          v-show="!(screenSize < 900)"
        ></mk-assignment-student-header>
        <mk-assignment-student-list
          v-for="(item, index) in sharedObject.submissions"
          :key="index"
          :submission-object="item"
          :number="index + 1"
          :total="sharedObject.total"
          :batch-id="sharedObject.id"
          @gradeUpdate="updateStudentGrades"
        ></mk-assignment-student-list>
      </div>
      <div v-else class="no-students">
        <v-row class="my-0">
          <v-col cols="12" align="center">
            <span>{{
              !$language
                ? "Students have yet to submit assignments"
                : "শিক্ষার্থীরা এখনো অ্যাসাইনমেন্ট জমা দেয়নি"
            }}</span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import MkAssignmentStudentHeader from "./MkAssignmentStudentHeader.vue";
import MkAssignmentStudentList from "./MkAssingmentStudentList.vue";
import courseworkService from "@ecf/modules/assignment/services/CourseworkService";

export default {
  name: "MkAssignmentExpansionPanel",
  components: { MkAssignmentStudentList, MkAssignmentStudentHeader },
  props: ["sharedObject"],
  inject: ["$courseId"],
  data() {
    return {
      active: false,
      evaluatedStudents: 0,
      publishig: {},
      studentGrades: {},
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    cols_info() {
      return this.screenSize < 600 ? 8 : this.screenSize < 900 ? 6 : 4;
    },
    cols_info_item() {
      return this.screenSize < 900 ? 12 : this.screenSize < 1600 ? 6 : 6;
    },
    cols_time() {
      return this.screenSize < 600 ? 12 : this.screenSize < 900 ? 10 : 5;
    },
    cols_action() {
      return this.screenSize < 600 ? 4 : this.screenSize < 900 ? 6 : 3;
    },
    order_time() {
      return this.screenSize < 900 ? "3" : "2";
    },
    order_time_item() {
      return this.screenSize < 600 ? 12 : 6;
    },
    order_action() {
      return this.screenSize < 900 ? "2" : "3";
    },
    icon_size() {
      return this.screenSize < 600
        ? "24"
        : this.screenSize < 1600
        ? "32"
        : "40";
    },
    computeEvaluatedStudents() {
      let count = 0;
      for (let item of this.sharedObject.submissions) {
        if ((item.grade || -1) >= 0) {
          count++;
        }
      }
      return count;
    },
    classes() {
      return {
        arrow: {
          false: 'icon-style',
          true: 'icon-style icon-active'
        },
      };
    },
  },
  async created() {
    this.populateStudentGrades();
  },
  methods: {
    customDateFormat(date, lang) {
      let langF = "";
      if (!lang) {
        langF = "en-gb";
      } else {
        langF = "bn-BD";
      }
      let customDate = new Date(date);
      return customDate.toLocaleString(langF, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    expandContent() {
      this.active = !this.active;
    },
    populateStudentGrades() {
      for (let item of this.sharedObject.submissions) {
        this.$set(this.studentGrades, item.id, item.grade);
      }
    },
    updateStudentGrades(payload) {
      this.$set(this.studentGrades, payload.id, payload.grade);
      this.$emit("updateBatch", {
        batch_id: this.sharedObject.id,
        student_id: payload.id,
        grade: payload.grade,
      });
    },
    async publish() {
      if(this.publishig[this.sharedObject.id]) return;
      try {
        this.$set(this.publishig, this.sharedObject.id, true);
        for (let item of Object.keys(this.studentGrades)) {
          if (
            this.studentGrades[item] === undefined ||
            this.studentGrades[item] < 0
          ) {
            this.$root.$emit("alert", [
              undefined,
              !this.$language
                ? `All students in this batch needs to be evaluated`
                : `এই ব্যাচের সকল শিক্ষার্থীকে মূল্যায়ন করতে হবে`,
            ]);
            return;
          }
          await courseworkService.grade(
            this.sharedObject.submissions[0].assignment_id,
            this.$courseId(),
            this.sharedObject.id, //batch id
            item, //student id
            this.studentGrades[item]
          );
        }
        await new Promise(res=>setTimeout(res, 750));
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.publishig, this.sharedObject.id, false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.abl-header {
  background-color: white;
  border: 0.5px solid #0099dc;
  border-radius: 6px;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  align-content: center;
  position: relative;
  top: 6px;
  margin-top: 10px;
  z-index: 1;

  @media screen and (min-width: 1601px) {
    width: 1400px;
    height: 100px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    width: 950px;
    height: 70px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    width: 500px;
    height: 120px;
    padding: 20px;
    padding-right: 5px;
  }

  @media screen and (max-width: 599px) {
    width: 305px;
    height: 135px;
    padding: 15px;
    padding-top: 12px;
    padding-right: 5px;
  }
}

.header-open {
  background-color: #ebf2ff;
}

.title-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: black;

  @media screen and (min-width: 1601px) {
    font-size: 22px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.eval-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: #5b5c5e;

  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.eval-value {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #202020;
  padding-left: 10px;

  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    padding-left: 6px;
  }
}

.time-desc-text {
  @media screen and (min-width: 1601px) {
    margin-left: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 599px) {
  }
}

.middle-text {
  @media screen and (min-width: 1601px) {
    margin-left: 35px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-left: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 599px) {
  }
}

.btn-publish {
  background-color: #0099dc !important;
  border-radius: 6px;

  @media screen and (min-width: 1601px) {
    height: 55px !important;
    width: 180px !important;
    margin-left: 55px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    height: 45px !important;
    width: 140px !important;
    margin-left: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    height: 45px !important;
    width: 140px !important;
    margin-left: 48px;
  }

  @media screen and (max-width: 599px) {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
    margin-left: 25px;
  }
}

.btn-icon {
  @media screen and (min-width: 1601px) {
    height: 40px;
    width: 40px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 599px) {
    height: 20px !important;
    width: 20px !important;
  }
}

.btn-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: white;
  margin-left: 10px;

  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.expand-arrow {
  @media screen and (min-width: 1601px) {
    margin-left: 50px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-left: 25px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-left: 5px;
  }

  @media screen and (max-width: 599px) {
    margin-left: 0px;
  }
}

.abl-content {
  background-color: white;
  border: 0.5px solid #0099dc;
  border-radius: 0px 0px 6px 6px;
  max-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  transition-property: max-height, padding;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0.2s;

  @media screen and (min-width: 1601px) {
    width: 1400px;
    padding: 0px 20px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    width: 950px;
    padding: 0px 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    width: 500px;
    padding: 0px 10px;
  }

  @media screen and (max-width: 599px) {
    width: 305px;
    padding: 0px 10px;
  }
}

.content-open {
  max-height: 100vh;
  transition-duration: 0.5s;
  transition-delay: 0s;

  @media screen and (min-width: 1601px) {
    padding: 5px 20px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 5px 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 5px 10px;
  }

  @media screen and (max-width: 599px) {
    padding: 5px 10px;
  }
}

.content-holder {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}

.holder-open {
  opacity: 1;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  transition-timing-function: ease-in;
}

.no-students {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.icon-style{
  transition: transform 0.1s ease-out;
  transition-delay: 0.1s;
}
.icon-active {
  transform: rotate(180deg);
}
</style>
