<template>
  <v-card flat class="card-style">
    <v-row no-gutters>
      <v-col :cols="cols_name" class="d-flex align-center">
        <v-row no-gutters>
          <v-col :cols="cols_name_part" class="d-flex align-center">
            <span class="num-text">
              {{ adjustNumFormat(number) }}
            </span>
            <span class="name-text">
              {{ submissionObject.name }}
            </span>
          </v-col>
          <v-col :cols="cols_grade_part" :class="{ 'py-2': screenSize < 900 }">
            <div class="grading-section">
              <span v-if="screenSize < 900" class="dtg-desc">Grade:</span>
              <span v-if="studentGrade === undefined" style="color: #fc6222">{{
                !$language ? "Not Evaluated" : "মূল্যায়ন করা হয় নি"
              }}</span>
              <span v-else style="color: #0099dc">{{ studentGrade }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :cols="cols_time"
        :order="order_time"
        :class="['d-flex align-center', { 'pb-2': screenSize < 900 }]"
      >
        <span v-if="screenSize < 900" class="dtg-desc"
          >{{ !$language ? "Date Appeared" : "তারিখ" }}:</span
        >
        <span class="date-text">{{
          customDateFormat(submissionObject.appeared)
        }}</span>
      </v-col>
      <v-col
        :cols="cols_button"
        :order="order_button"
        class="d-flex align-center"
      >
        <v-dialog v-model="popUpActive" :width="dialogWidth">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="#0099dc"
              class="eval-btn"
              v-bind="attrs"
              v-on="on"
            >
              <span class="btn-text">{{
                !$language ? "Evaluate" : "মূল্যায়ন করুন"
              }}</span>
            </v-btn>
          </template>

          <submission-list
            v-if="submissionList"
            :shared-object="submissionObject"
            :list-position="number"
            :urls="urls"
            :grade-stat="gradeStatus"
            @close="popUpActive = false"
            @submit="submitGrade"
            @assesment="
              (e) =>
                $router.push({
                  name: 'assignment-assessment',
                  params: {
                    assignmentId: submissionObject.assignment_id,
                    index: e.index.toString(),
                    studentId,
                    ext: e.ext,
                  },
                })
            "
          ></submission-list>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import courseworkService from "@ecf/modules/assignment/services/CourseworkService";
import SubmissionList from "@ecf/modules/assignment/components/SubmissionList";

export default {
  name: "MkAssignmentStudentList",
  components: { SubmissionList },
  props: ["submissionObject", "number", "total", "batchId"],
  inject: ["$courseId"],
  data() {
    return {
      submissionDetails: {},
      urls: {},
      active: false,
      submissionList: false,
      popUpActive: false,
      studentId: this.submissionObject.id,
      grading: {},
      studentGrade: this.submissionObject.grade,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    cols_name() {
      return 8;
    },
    cols_name_part() {
      return this.screenSize < 900 ? 12 : 10;
    },
    cols_grade_part() {
      return this.screenSize < 900 ? 6 : 2;
    },
    cols_time() {
      return this.screenSize < 600 ? 12 : this.screenSize < 900 ? 10 : 2;
    },
    cols_dt() {
      return this.screenSize < 600 ? 12 : 6;
    },
    cols_button() {
      return this.screenSize < 900 ? 4 : 2;
    },
    order_time() {
      return this.screenSize < 900 ? "3" : "2";
    },
    order_button() {
      return this.screenSize < 900 ? "2" : "3";
    },
    dialogWidth() {
      return this.screenSize < 600
        ? 305
        : this.screenSize < 900
        ? 500
        : this.screenSize < 1600
        ? 950
        : 1400;
    },
    gradeStatus() {
      if (this.submissionObject.grade >= 0) {
        return {
          grade: this.submissionObject.grade,
          place_holder: "",
          suffix: `/${this.total}`,
        };
      } else {
        return {
          grade: "--",
          place_holder: "Not Evaluated",
          suffix: `/${this.total}`,
        };
      }
    },
  },
  async created() {
    await this.fetchSubmissionDetails();
    this.studentGrade = this.submissionObject.grade;
  },
  methods: {
    adjustNumFormat(num) {
      let n = Math.max(Math.ceil(Math.log(num)), 2);
      return String(num).padStart(n, 0);
    },
    customDateFormat(date) {
      let section = date.split("-");
      let customDate = new Date(section[0], Number(section[1]) - 1, section[2]);
      if (!this.$language)
        return customDate.toLocaleString("en-gb", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      else
        return customDate.toLocaleString("bn-BD", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
    },
    evaluationStatus(examObject) {
      if (examObject.score > 0) {
        return `<span style="color: #0099dc;">${examObject.score.toFixed(
          1
        )}</span>`;
      } else {
        return `<span style="color: #FC6222">Not Evaluated</span>`;
      }
    },
    async fetchSubmissionDetails() {
      try {
        const submissionDetails = await courseworkService.getSubmission(
          this.$courseId(),
          this.submissionObject.assignment_id,
          this.submissionObject.id
        );

        this.submissionDetails = submissionDetails;
        this.urls = [...submissionDetails.url];
        this.submissionList = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async submitGrade(payload) {
      try {
        this.studentGrade = payload;
        this.popUpActive = false;
        this.$emit("gradeUpdate", { id: this.studentId, grade: payload });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-style {
  border: 0.5px solid #b7b7b7;
  border-radius: 6px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;

  color: #202020;

  @media screen and (min-width: 1601px) {
    margin-top: 10px;
    padding: 15px 40px;
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-top: 6px;
    padding: 8px 20px;
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-top: 6px;
    font-size: 12px;
    padding: 20px;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 599px) {
    margin-top: 6px;
    font-size: 12px;
    padding: 10px;
    padding-bottom: 4px;
  }
}

.name-text {
  @media screen and (min-width: 1601px) {
    margin-left: 10px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-left: 6px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    font-weight: 500;
    margin-left: 6px;
  }
}

.num-text {
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    font-weight: 500;
  }
}

.date-text {
  @media screen and (min-width: 1601px) {
    margin-left: 40px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    margin-left: 25px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-weight: 500;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    font-weight: 500;
  }
}

.dtg-desc {
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    color: #5b5c5e;
    font-weight: 300;
    margin-right: 10px;
  }

  @media screen and (max-width: 599px) {
    color: #5b5c5e;
    font-size: 10px;
    margin-right: 4px;
  }
}

.eval-btn {
  border-radius: 6px;
  @media screen and (min-width: 1601px) {
    height: 50px !important;
    width: 120px !important;
    margin-left: 85px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    height: 35px !important;
    width: 90px !important;
    margin-left: 45px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    height: 35px !important;
    width: 90px !important;
    margin-left: 55px;
  }

  @media screen and (max-width: 599px) {
    height: 35px !important;
    width: 90px !important;
    margin-left: 0px;
  }
}

.btn-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: white;

  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
</style>
