var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-style", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_name },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: _vm.cols_name_part },
                    },
                    [
                      _c("span", { staticClass: "num-text" }, [
                        _vm._v(
                          " " + _vm._s(_vm.adjustNumFormat(_vm.number)) + " "
                        ),
                      ]),
                      _c("span", { staticClass: "name-text" }, [
                        _vm._v(" " + _vm._s(_vm.submissionObject.name) + " "),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: { "py-2": _vm.screenSize < 900 },
                      attrs: { cols: _vm.cols_grade_part },
                    },
                    [
                      _c("div", { staticClass: "grading-section" }, [
                        _vm.screenSize < 900
                          ? _c("span", { staticClass: "dtg-desc" }, [
                              _vm._v("Grade:"),
                            ])
                          : _vm._e(),
                        _vm.studentGrade === undefined
                          ? _c("span", { staticStyle: { color: "#fc6222" } }, [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language
                                    ? "Not Evaluated"
                                    : "মূল্যায়ন করা হয় নি"
                                )
                              ),
                            ])
                          : _c("span", { staticStyle: { color: "#0099dc" } }, [
                              _vm._v(_vm._s(_vm.studentGrade)),
                            ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: ["d-flex align-center", { "pb-2": _vm.screenSize < 900 }],
              attrs: { cols: _vm.cols_time, order: _vm.order_time },
            },
            [
              _vm.screenSize < 900
                ? _c("span", { staticClass: "dtg-desc" }, [
                    _vm._v(
                      _vm._s(!_vm.$language ? "Date Appeared" : "তারিখ") + ":"
                    ),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "date-text" }, [
                _vm._v(
                  _vm._s(_vm.customDateFormat(_vm.submissionObject.appeared))
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_button, order: _vm.order_button },
            },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: _vm.dialogWidth },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "eval-btn",
                                  attrs: { depressed: "", color: "#0099dc" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("span", { staticClass: "btn-text" }, [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Evaluate"
                                      : "মূল্যায়ন করুন"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.popUpActive,
                    callback: function ($$v) {
                      _vm.popUpActive = $$v
                    },
                    expression: "popUpActive",
                  },
                },
                [
                  _vm.submissionList
                    ? _c("submission-list", {
                        attrs: {
                          "shared-object": _vm.submissionObject,
                          "list-position": _vm.number,
                          urls: _vm.urls,
                          "grade-stat": _vm.gradeStatus,
                        },
                        on: {
                          close: function ($event) {
                            _vm.popUpActive = false
                          },
                          submit: _vm.submitGrade,
                          assesment: (e) =>
                            _vm.$router.push({
                              name: "assignment-assessment",
                              params: {
                                assignmentId:
                                  _vm.submissionObject.assignment_id,
                                index: e.index.toString(),
                                studentId: _vm.studentId,
                                ext: e.ext,
                              },
                            }),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }