var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-style", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_name },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: _vm.cols_name_part } }, [
                    _c("span", { staticClass: "num-text" }, [
                      _vm._v(_vm._s(!_vm.$language ? " No." : "ক্রমিক.")),
                    ]),
                    _c("span", { staticClass: "name-text" }, [
                      _vm._v(
                        " " + _vm._s(!_vm.$language ? "Name" : "নাম") + " "
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: _vm.cols_grade_part } }, [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(!_vm.$language ? "Grade" : "গ্রেড") + " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_time },
            },
            [
              _c("span", { staticClass: "date-text" }, [
                _vm._v(
                  " " + _vm._s(!_vm.$language ? "Date Appeared" : "তারিখ") + " "
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_button },
            },
            [
              _c("span", { staticClass: "action-text" }, [
                _vm._v(" " + _vm._s(!_vm.$language ? "Actions" : "অ্যাকশন")),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }