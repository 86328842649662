<template>
  <v-card flat class="card-style">
    <v-row no-gutters>
      <v-col :cols="cols_name" class="d-flex align-center">
        <v-row no-gutters>
          <v-col :cols="cols_name_part">
            <span class="num-text">{{ !$language ? " No." : "ক্রমিক." }}</span>
            <span class="name-text"> {{ !$language ? "Name" : "নাম" }} </span>
          </v-col>
          <v-col :cols="cols_grade_part">
            <span> {{ !$language ? "Grade" : "গ্রেড" }} </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="cols_time" class="d-flex align-center">
        <span class="date-text">
          {{ !$language ? "Date Appeared" : "তারিখ" }}
        </span>
      </v-col>
      <v-col :cols="cols_button" class="d-flex align-center">
        <span class="action-text">
          {{ !$language ? "Actions" : "অ্যাকশন" }}</span
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MkAssignmentStudentHeader",
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    cols_name() {
      return 8;
    },
    cols_name_part() {
      return this.screenSize < 900 ? 12 : 10;
    },
    cols_grade_part() {
      return this.screenSize < 900 ? 12 : 2;
    },
    cols_time() {
      return this.screenSize < 900 ? 10 : 2;
    },
    cols_button() {
      return this.screenSize < 900 ? 4 : 2;
    }
  }
};
</script>

<style scoped lang="scss">
.card-style {
  margin-top: 10px;
  padding: 15px 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 18px;

  @media screen and (max-width: 1601px) {
    margin-top: 6px;
    padding: 8px 20px;
    font-size: 14px;
  }
}

.name-text {
  margin-left: 10px;

  @media screen and (max-width: 1601px) {
    margin-left: 6px;
  }
}

.date-text {
  margin-left: 35px;

  @media screen and (max-width: 1601px) {
    margin-left: 25px;
  }
}

.action-text {
  margin-left: 85px;

  @media screen and (max-width: 1601px) {
    margin-left: 45px;
  }
}
</style>
