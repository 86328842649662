<template>
  <v-card flat class="rounded-5">
    <v-card
      flat
      class="d-flex align-center container-padding rounded-5 rounded-b-0"
      color="#0099dc"
    >
      <span class="top-text"
        >{{ correctNum(listPosition) }}. {{ sharedObject.name }}</span
      >
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="white rounded-3"
        :height="buttonSize.height"
        :width="buttonSize.width"
      >
        <v-icon color="#0099dc" :size="iconSize">mdi-tray-arrow-down</v-icon>
        <span class="top-text download-all-text" @click="downloadAll">{{
          !$language ? "Download All" : "সব ডাউনলোড করুন"
        }}</span>
      </v-btn>
    </v-card>
    <div class="container-padding">
      <v-card flat class="py-5 px-3">
        <span class="upload-text">{{
          !$language ? "Uploaded Files:" : "আপলোডকৃত ফাইলসমূহ:"
        }}</span>
        <span class="upload-text upload-value">{{ upLoadedFileCount }}</span>
      </v-card>
      <v-card
        v-for="(url, index) in urls"
        :key="index"
        flat
        class="d-flex align-center py-3 px-3"
      >
        <span class="list-text">{{ correctNum(index + 1) }}.</span>

        <img class="file-icon" :src="correctFileIcon(url)" />
        <span class="list-text text-truncate">{{ getFileName(url) }}</span>
        <v-spacer></v-spacer>
        <v-btn
          color="#0099dc"
          class="rounded-3 white--text"
          :height="innerButtonSize.height"
          :width="innerButtonSize.width"
          depressed
          @click="
            $emit('assesment', { index, ext: getFileName(url).split('.')[1] })
          "
          ><span class="list-text">{{
            !$language ? "Preview" : "দেখুন"
          }}</span></v-btn
        >
      </v-card>
      <!-- kljklj -->
      <!-- <div class="d-flex justify-space-around">
        <span class="out-of-text">/10000</span>
      </div> -->
      <div class="d-flex flex-column justify-center align-center mt-5 py-2">
        <div
          class="d-flex align-center rounded outer-parameter"
          :class="{ 'box-invalid': gradeInvalid }"
        >
          <div
            class="d-flex flex-grow-1 align-center justify-center px-2 rounded rounded-r-0 xl:text-lg lg:text-lg md:text-base sm:text-sm input-parameter"
            style="height: 40px;"
            tabindex="0"
          >
            <input
              v-model="score"
              type="text"
              style="text-align: right; width: 100px"
              class="grade-input"
            /><span>{{ gradeStat.suffix }}</span>
          </div>
          <v-btn
            depressed
            :color="gradeInvalid ? 'decline' : 'primary'"
            class="white--text rounded rounded-l-0"
            width="120"
            height="40"
            @click="setScore"
            >{{ !$language ? "Set Grade" : "গ্রেড সেট করুন" }}
          </v-btn>
        </div>
        <div style="height: 40px" class="d-flex align-center">
          <v-slide-y-transition>
            <span
              v-show="gradeInvalid"
              class="decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs"
            >
              Grade must be between 0 and {{ maxScore }}
            </span>
          </v-slide-y-transition>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import courseworkService from "@ecf/modules/assignment/services/CourseworkService";
import { download } from "/global/utils/helpers";

export default {
  name: "SubmissionList",
  props: {
    urls: {
      type: Array,
      required: true
    },
    sharedObject: Object,
    listPosition: Number,
    gradeStat: Object
  },
  inject: ["$courseId"],
  data() {
    return {
      score: this.gradeStat.grade,
      maxScore: this.gradeStat.suffix.split("/")[1],
      docUrls: [],
      gradeInvalid: false
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    buttonSize() {
      return this.screenSize < 600
        ? { height: 30, width: 100 }
        : this.screenSize < 900
        ? { height: 40, width: 150 }
        : this.screenSize < 1600
        ? { height: 45, width: 180 }
        : { height: 55, width: 230 };
    },
    innerButtonSize() {
      return this.screenSize < 600
        ? { height: 25, width: 60 }
        : this.screenSize < 1600
        ? { height: 35, width: 110 }
        : { height: 40, width: 115 };
    },
    iconSize() {
      return this.screenSize < 600
        ? "16"
        : this.screenSize < 1600
        ? "24"
        : "32";
    },
    upLoadedFileCount() {
      return this.urls.length;
    }
  },
  watch: {
    score: {
      handler(value, old) {
        if (value === "" || value === undefined) {
          this.score = "0";
        } else if (isNaN(parseInt(value))) {
          this.score = old;
        } else {
          this.score = String(parseInt(value));
        }
        if (parseInt(this.score) > parseInt(this.maxScore))
          this.gradeInvalid = true;
        else this.gradeInvalid = false;
      }
    }
  },
  async created() {
    await this.fetchStudentAndSubmission();
  },
  methods: {
    getFileName(url) {
      return decodeURIComponent(
        url
          .split("?")[0]
          .split("/")
          .pop()
      );
    },
    download(url) {
      window.open(url);
    },
    correctFileIcon(url) {
      let extension = this.getFileName(url).split(".")[1];
      if (extension == "pdf") {
        return require("#ecf/assignment/assets/pdf-svgrepo-com.svg");
      } else if (extension == "doc" || extension == "docx") {
        return require("#ecf/assignment/assets/doc-svgrepo-com.svg");
      } else if (extension == "txt") {
        return require("#ecf/assignment/assets/txt-svgrepo-com.svg");
      } else if (
        extension == "jpg" ||
        extension == "png" ||
        extension == "jpeg"
      ) {
        return require("#ecf/assignment/assets/picture-svgrepo-com.svg");
      } else if (
        extension == "ppt" ||
        extension == "pptx" ||
        extension == "pptm"
      ) {
        return require("#ecf/assignment/assets/ppt-svgrepo-com.svg");
      } else if (
        extension == "xl" ||
        extension == "xls" ||
        extension == "xlsx" ||
        extension == "xlsm"
      ) {
        return require("#ecf/assignment/assets/xls-svgrepo-com.svg");
      } else if (
        extension == "mp4" ||
        extension == "m4v" ||
        extension == "m4v" ||
        extension == "webm" ||
        extension == "avi" ||
        extension == "mkv" ||
        extension == "wmv" ||
        extension == "mpeg"
      ) {
        return require("#ecf/assignment/assets/video-svgrepo-com.svg");
      } else {
        return require("#ecf/assignment/assets/file-svgrepo-com.svg");
      }
    },
    correctNum(num) {
      let n = Math.max(Math.ceil(Math.log(num)), 2);
      return String(num).padStart(n, 0);
    },
    setScore() {
      if (this.gradeInvalid) return;
      this.$emit("submit", Number(this.score));
    },
    async fetchStudentAndSubmission() {
      try {
        await this.$store.dispatch("student/list", {
          courseId: this.$courseId(),
          partner_code: this.$route.params.partner_code
        });
        let docs = await courseworkService.getSubmission(
          this.$courseId(),
          this.sharedObject.assignment_id,
          this.sharedObject.id
        );
        for (let i = 0; i < docs.url.length; i++) {
          let url;
          if (docs.assessed_url[i].length > 0) {
            // file previously assessed
            url = docs.assessed_url[i];
          } else {
            // pdf don't need conversion
            url = docs.url[i];
          }
          this.docUrls.push(url);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    async downloadAll() {
      for (let downloadUrls of this.docUrls) {
        let retBlob = await download(downloadUrls);
        let fileName = this.getFileName(downloadUrls);

        const blobUrl = URL.createObjectURL(retBlob);

        let link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#tf {
  font-size: 20px;
}

.top-text {
  font-family: roboto;
  color: white;
  font-weight: 500;

  @media screen and (min-width: 1600px) {
    font-size: 25px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.container-padding {
  @media screen and (min-width: 1600px) {
    padding: 15px 60px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    padding: 10px 40px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    padding: 10px 30px;
  }

  @media screen and (max-width: 599px) {
    padding: 5px 20px;
  }
}

.download-all-text {
  color: #0099dc;
  font-weight: 400;

  @media screen and (min-width: 1600px) {
    font-size: 20px;
    padding-left: 10px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 16px;
    padding-left: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 16px;
    padding-left: 6px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
    padding-left: 4px;
    letter-spacing: 0px;
  }
}

.upload-text {
  font-weight: 500;

  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 1599px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.upload-value {
  color: #0099dc;
  font-weight: 400;

  @media screen and (min-width: 1600px) {
    padding-left: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 1599px) {
    padding-left: 8px;
  }

  @media screen and (max-width: 599px) {
    padding-left: 5px;
  }
}

.list-text {
  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.file-icon {
  @media screen and (min-width: 1600px) {
    height: 30px;
    padding-left: 20px;
    padding-right: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 1599px) {
    height: 25px;
    padding-left: 15px;
    padding-right: 10px;
  }

  @media screen and (max-width: 599px) {
    height: 20px;
    padding-left: 7px;
    padding-right: 6px;
  }
}

.submit-text {
  font-family: roboto;
  font-weight: 400;
  color: white;

  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }
}

.outer-parameter:focus-within {
  outline: 2px solid $primary;
}

.box-invalid:not(:focus-within) {
  .input-parameter {
    border-color: $decline;
  }
}

.box-invalid:focus-within {
  outline-color: $decline;
}

.input-parameter {
  border: 1px solid $border;
  border-right-width: 0;
}

.grade-input:focus {
  outline: none;
}

.btn-style {
  // font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 0px 6px 6px 0px;
  height: 40px !important;
  width: 120px;
  position: relative;
}
</style>
